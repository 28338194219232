import React from 'react';
import { Linkable, LinkableSchema, useDocumentLinks } from './useDocumentLinks';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { Buttons, LoadingIndicator } from '../../../toolympus/components/primitives';
import { MaybeFieldDefinition } from '../../../toolympus/components/schemed';
import { SelectItemDialog } from '../../../toolympus/components/primitives/SelectItemDialog';
import { LinkBlipGeneric, RecordTypeLabel, RecordTypeLinkPrefix } from '../Links/LinksBlips';

interface Props {
  thisRecordType: string;
  thisRecordId: number;
}

const SelectDialogFields: MaybeFieldDefinition[] = [
  ["record_type"],
  ["title"],
];

const linkableLink = (l: Linkable) => {
  const prefix = RecordTypeLinkPrefix[l.record_type];
  return prefix
    ? `${prefix}/${l.record_id}`
    : "";
}

const linkableFieldLink = (f: string) => {
  if(f === "title") {
    return linkableLink;
  }
}

export const DocumentLinksPane = (props: Props) => {
  const data = useDocumentLinks(props.thisRecordType, props.thisRecordId);

  return (
    <Buttons vertical>
      {data.data.map(l => (
        <LinkBlipGeneric
          key={l.link_id}
          description={`${RecordTypeLabel[l.record_type]}: ${l.title}`}
          url={linkableLink(l)}
          onDelete={() => data.remove.run(l)}
          />
      ))}

      <Button size="small" color="primary" startIcon={<Add />} onClick={() => data.addDialog.open()}>associate a document</Button>

      {data.isLoading && <LoadingIndicator sizeVariant="s" />}

      <SelectItemDialog
        available={data.linkable.data}
        filter={data.search}
        setFilter={data.setSearch}
        close={data.addDialog.close}
        isOpen={data.addDialog.isOpen}
        isLoading={data.linkable.isLoading}
        title="Associate a document"
        select={l => data.add(l)}
        schema={LinkableSchema}
        fields={SelectDialogFields}
        fieldLink={linkableFieldLink}
        />
    </Buttons>
  );
}
