import React, { ReactNode } from 'react';
import { AutoexpanderDefault, Dialog, DialogState, FormGrid, LoadingIndicator, SearchField } from '.';
import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { MaybeFieldDefinition, TableForFields, TableProps } from '../schemed';
import { Schema } from '../../hooks/useSchema';


interface SelectItemDialogProps<T,> extends Pick<DialogState, "isOpen" | "close"> {
  filter: string;
  setFilter: (v: string) => void;
  available: T[];
  isLoading: boolean;
  select: (l: T) => void;
  title: ReactNode;
  schema: Schema;
  fields: MaybeFieldDefinition[];
  fieldLink?: TableProps<T>["fieldLink"];
  selectLabel?: ReactNode;
}

export const SelectItemDialog = <T,>(props: SelectItemDialogProps<T>) => {
  return (
    <Dialog
      isOpen={props.isOpen}
      close={props.close}
      dialogTitle={props.title}
      titleActions={<>
        {props.isLoading && <LoadingIndicator sizeVariant="s" />}
      </>}
      actions={<>
        <Button size="small"><FormattedMessage id="common.close" /></Button>
      </>}>
      <FormGrid columns="1fr" noMargin>
        <SearchField
          filter={props.filter}
          setFilter={props.setFilter}
          autoFocus
          noButton
          fullWidth
          doSearch={() => {
            if(props.available.length === 1) {
              props.select(props.available[0]);
              props.close();
            }
          }}
          />

          <TableForFields
            fields={props.fields}
            data={props.available}
            autoexpander={AutoexpanderDefault}
            schema={props.schema}
            fieldLink={props.fieldLink}
            fieldLinkNewTab
            dense
            rowButtons={r => (
              <Button size="small" color="primary" onClick={() => {
                props.select(r);
                props.close();
              }}>
                {props.selectLabel || <FormattedMessage id="common.select" />}
              </Button>
            )}
            />
      </FormGrid>
    </Dialog>
  );
}
