import { useBrowserStoredValue } from "../../toolympus/hooks/useBrowserStoredValue";
import { useSingleSchema } from "../../toolympus/hooks/useSchema";
import { useSmartList } from "../../toolympus/hooks/useSmartList";

export interface Instrument {
  _id: number;
  title: string;
  
  date_adoption?: string;
  date_effective?: string;
  
  topics?: string[] | null;
  
  depositary_url?: string;
  source_url?: string;

  depositary?: string;
  countries?: string[];

  content?: any;
  description?: any;

  is_published?: boolean;
  
  owner_id?: string;
  created_at?: string;
  created_by?: string;
}

export const InstrumentApiPath = "/api/instrument";

const InstrumentTextFilter = (i: Instrument) => `${i.title} ${i._id}`;


export const useInstrumentSchema = () => {
  const { schema } = useSingleSchema(`${InstrumentApiPath}/uiconfig`);
  return schema;
}

export const useInstrumentsList = () => {
  const schema = useInstrumentSchema();
  
  const assignedFilter = useBrowserStoredValue("me", undefined, "__iclrcbd_instrumentlist_my_all");

  const data = useSmartList(InstrumentApiPath, {
    sorting: { disallowedFields: ["description", "content"] },
    extraParams: {
      assigned: assignedFilter.value === "me" ? "me" : undefined,
    },
    viewDefault: "unpublished",
    lsKeysPrefix: "__iclrcbd_instrumentlist",
    newRecordDefault: { title: "" },
    schema,
    textFilterFn: InstrumentTextFilter,
  });

  return {
    ...data,
    assignedFilter,
  }
}

export type InstrumentListData = ReturnType<typeof useInstrumentsList>;
