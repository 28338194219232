import React from 'react';
import { FormGrid, SimpleDialog } from '../../../../toolympus/components/primitives';
import { Topic, TopicSchema } from './useManageTopics';
import { useItemWithControls } from '../../../../toolympus/api/useWithControls';
import { EditItemProps, NewItemProps } from '../../../../toolympus/api/useNewItem';

type Props = (EditItemProps<Topic> | NewItemProps<Partial<Topic>, Topic>) & { isCreate?: boolean };


export const TopicEditDialog = (props: Props) => {
  const { isEditing, cancel, item, update, errors, save } = props;

  const { controls } = useItemWithControls({ data: item || {}, update, errors }, { schema: TopicSchema });

  return (
    <SimpleDialog
      dialogTitle="Topic"
      submitOnModEnter
      close={cancel}
      isOpen={isEditing}
      save={save}
      maxWidth="md">

        <FormGrid columns="1fr 1fr">
          {item && controls([
              ["title", { controlProps: { autoFocus: true } }],
              ["sort_order"],
              props.isCreate ? null : ["created_by", { disabled: true }],
              props.isCreate ? null : ["created_at", { disabled: true, utcToLocal: true }],
            ])}

        </FormGrid>
      
    </SimpleDialog >
  );
}

export const NewTopicDialog = (props: Props) => {
  return (
    <TopicEditDialog
      {...props}
      isCreate
      />
  )
}
