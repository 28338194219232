import { useMemo, useState } from "react";
import { apiFetch } from "../../../toolympus/api/core";
import { useItemActionWithConfirmation } from "../../../toolympus/api/useAction";
import { useDialogState, useThrottledCapture } from "../../../toolympus/components/primitives";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { createSelectSchema, Schema } from "../../../toolympus/hooks/useSchema";

export interface DocumentLink {
  record_type: string;
  record_id: number;
  title: string;
  link_id: string;
}

export interface Linkable {
  record_type: string;
  record_id: number;
  title: string;
}

export const LinkableSchema: Schema = {
  title: { label: "Title" },
  record_type: createSelectSchema([
    { value: "case_record", label: "Case" },
    { value: "instrument", label: "Instrument" },
  ], { label: "Type" }),
}

export const DocumentLinksApiPath = `/api/document-links`;

export const useDocumentLinks = (thisRecordType: string, thisRecordId: number) => {
  const existingLinks = useLoadedData<DocumentLink[]>(`${DocumentLinksApiPath}/${thisRecordType}/${thisRecordId}`, [], !!thisRecordType && !!thisRecordId && thisRecordId >= 0);

  const doRemove = (l: DocumentLink) => {
    return apiFetch(`${DocumentLinksApiPath}/${l.link_id}`, "delete")
      .then(() => {
        existingLinks.reload();
      });
  }

  const remove = useItemActionWithConfirmation(doRemove, {
    title: "Remove link",
    memoTrigger: [thisRecordId],
  });

  const add = (l: Linkable) => {
    return apiFetch(`${DocumentLinksApiPath}`, "post", {
      this_record_type: thisRecordType,
      this_record_id: thisRecordId,
      that_record_type: l.record_type,
      that_record_id: l.record_id,
    })
    .then(() => {
      existingLinks.reload();
    });
  }

  const [search, setSearch] = useState<string>("");
  const searchApplied = useThrottledCapture(search, 500);
  const addDialog = useDialogState();
  const linkable = useLoadedData<Linkable[]>(`${DocumentLinksApiPath}/linkable?search=${searchApplied}`, [], addDialog.isOpen && !!searchApplied && !!search);

  const linkableFiltered = useMemo(() => {
    return linkable.data.filter(l => (
      !(l.record_type === thisRecordType && l.record_id === thisRecordId)
      && !existingLinks.data.find(lx => lx.record_type === l.record_type && lx.record_id === l.record_id)
    ))
  }, [linkable.data, thisRecordType, thisRecordId, existingLinks.data]);

  return {
    ...existingLinks,
    remove,
    add,

    addDialog,
    linkable: {
      ...linkable,
      data: linkableFiltered,
    },
    search,
    setSearch,
  }
}
