import { ReactNode, useCallback, useMemo, useState } from "react";
import { EditItemProps, NewItemProps, useEditItem2, useNewItem } from "../../../toolympus/api/useNewItem";
import { TextFilter, useTextFilter } from "../../../toolympus/components/schemed/Filtering/useTextFilter";
import { LoadedData, useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { apiFetch } from "../../../toolympus/api/core";
import { useIntl } from "react-intl";
import { ActionOnItemWithConfirmation, useItemActionWithConfirmation } from "../../../toolympus/api/useAction";
import { TreeItemsConfig, ItemsTree, treeWithDescendantsCodes, assembleTree, disassembleTree, pathToRoot, TreeNode } from "./treeHelpers";


export interface TreeItemsConfigWithManage<T> extends TreeItemsConfig<T> {
  textFilterBy?: (item: T) => string;
  itemTitle: (item: T) => ReactNode;
  newItemDefault: Partial<T>;
}
export const ConstantlyEmpty = () => "";

interface ChangeParentData<T> {
  item: T | null;
  newParent: T | "root" | null;
  movedSubtreeCodes: any[];
  startChanging: (item: T) => void;
  selectNewParent: (item: T | "root") => void;
  commitChange: () => void;
  cancel: () => void;
}

export const useChangeParent = <T,>(cfg: TreeItemsConfig<T>, apiPath: string, data: LoadedData<T[]>, tree: ItemsTree<T>): ChangeParentData<T> => {
  const [itemToChangeParent, setItemToChangeParent] = useState<T | null>(null);
  const [itemNewParent, setItemNewParent] = useState<T | "root" | null>(null);
  const itemToChangeParentSubtreeCodes = useMemo(
    () => itemToChangeParent ? treeWithDescendantsCodes(cfg, tree, itemToChangeParent[cfg.idField]) : [],
    [itemToChangeParent, tree, cfg]);

  const startChanging = useCallback((item: T) => setItemToChangeParent(item), []);

  return {
    item: itemToChangeParent,
    newParent: itemNewParent,
    movedSubtreeCodes: itemToChangeParentSubtreeCodes,
    startChanging,
    selectNewParent: (item: T | "root") => {
      if(item === "root") {
        setItemNewParent("root");
      }
      else if(!itemToChangeParentSubtreeCodes.find(code => code === item[cfg.idField])) {
        setItemNewParent(item);
      }
    },
    commitChange: () => {
      if(itemToChangeParent && itemNewParent) {
        apiFetch<T>(`${apiPath}/${itemToChangeParent[cfg.idField]}`, "put", { [cfg.parentIdField]: itemNewParent === "root" ? null : itemNewParent[cfg.idField] })
          .then(() => {
            setItemToChangeParent(null);
            setItemNewParent(null);
            data.reload();
          })
      }
    },
    cancel: () => { setItemToChangeParent(null); setItemNewParent(null); },
  }
}

export interface CollapseTreeData<T> {
  collapse: (item: T) => void;
  uncollapse: (item: T) => void;
  toggleCollapsed: (item: T) => void;
  collapseAll: () => void;
  uncollapseAll: () => void;
  
  isCollapsed: (item: T) => boolean;
  isHidden: (item: T) => boolean;
  isCollapsible: (item: T) => boolean;
}

export const useCollapseTree = <T,>(cfg: TreeItemsConfig<T>, tree: ItemsTree<T>): CollapseTreeData<T> => {
  const [collapsed, setCollapsed] = useState<any[]>([]);
  
  const isCollapsed = useCallback(
    (item: T) => !!collapsed.find(c => c === item[cfg.idField]),
    [collapsed, cfg]);

  const isCollapsible = useCallback(
    (item: T) => ((tree[item[cfg.idField]] as unknown as TreeNode<T>)?.children || []).length > 0,
    [tree, cfg]);

  const hidden = useMemo(() => {
    const allHidden = collapsed
      .map(code => treeWithDescendantsCodes(cfg, tree, code).slice(1))
      .reduce((r,codes) => { r.push(...codes); return r; }, []);
    return new Set(allHidden);
  }, [tree, collapsed, cfg]);

  const isHidden = useCallback((item: T) => {
    return hidden.has(item[cfg.idField]);
  }, [hidden, cfg]);

  const toggleCollapsed = useCallback((item: T) => setCollapsed(x => {
    const without = x.filter(c => c !== item[cfg.idField]);
    return without.length === x.length ? [...x, item[cfg.idField]] : without;
  }), [cfg]);
    
  return {
    collapse: (item: T) => setCollapsed(x => [...x, item[cfg.idField]]),
    uncollapse: (item: T) => setCollapsed(x => x.filter(c => c !== item[cfg.idField])),
    toggleCollapsed,
    collapseAll: () => {
      const nonLeafs = Object.values(tree).filter(node => (node.children || []).length > 0).map(node => node.node ? node.node[cfg.idField] : undefined);
      setCollapsed(nonLeafs.filter(x => !!x) as any[]);
    },
    uncollapseAll: () => setCollapsed([]),
    
    isCollapsed,
    isHidden,
    isCollapsible,
  }
}

export interface ManageTreeData<T> extends LoadedData<T[]> {
    filter: TextFilter<T>;

    newItem: NewItemProps<Partial<T>, T>;
    editItem: EditItemProps<T>;
    removeItem: ActionOnItemWithConfirmation<T, {}>;

    changeParent: ChangeParentData<T>;
    collapser: CollapseTreeData<T>;
    config: TreeItemsConfigWithManage<T>;
}

export const useManageTree = <T,>(cfg: TreeItemsConfigWithManage<T>, apiPath: string): ManageTreeData<T> => {
  const data = useLoadedData<T[]>(apiPath, []);
  const filter = useTextFilter<T>(cfg.textFilterBy || ConstantlyEmpty);


  const [treeData,tree] = useMemo(
    () => {
      const tree = assembleTree(cfg, data.data);
      return [disassembleTree(cfg, tree), tree];
    },
    [data.data, cfg]);

  const filtered = useMemo(
    () => {
      if(filter.filter.length === 0) {
        return treeData;
      }

      const matchingItems = filter.filterData(treeData);
      const codesWithParents = matchingItems.map(item => pathToRoot(cfg, tree, item[cfg.idField])).reduce((r,x) => { r.push(...x); return r; }, []);
      const fullCodesSet = new Set(codesWithParents);
      return treeData.filter(item => fullCodesSet.has(item[cfg.idField]));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [treeData, filter.filter]
  )

  const maxSortOrder = Math.max(...data.data.map(item => item[cfg.sortField] as unknown as number), 0);

  const newItemDefault = useMemo(() => ({
    ...cfg.newItemDefault,
    [cfg.sortField]: maxSortOrder + 1,
  }), [cfg, maxSortOrder])

  const newItem = useNewItem<Partial<T>, T>(apiPath, newItemDefault);

  const editItem = useEditItem2<T>({
    getApiPath: c => `${apiPath}/${c[cfg.idField]}`,
  });

  const { formatMessage } = useIntl();

  const removeItem = useItemActionWithConfirmation<T, {}>(
    item => apiFetch<{}>(`${apiPath}/${item[cfg.idField]}`, "delete")
      .then(x => {
        data.reload();
        return x;
    }),
    {
      title: formatMessage({ id: "dictionaries.remove_title" }),
      confirmationHint: formatMessage({ id: "dictionaries.remove_hint" }),
    }
  );

  const changeParent = useChangeParent(cfg, apiPath, data, tree);

  const collapser = useCollapseTree(cfg, tree);

  return {
    ...data,
    data: filtered,
    filter,

    newItem: {
      ...newItem,
      save: () => newItem.save().then(c => { data.reload(); return c; }),
    },
    editItem: {
      ...editItem,
      save: () => editItem.save().then(c => { data.reload(); return c; }),
    },
    removeItem,

    changeParent,
    collapser,
    
    config: cfg,
  }
}
