import React from 'react';
import { useParams } from 'react-router-dom';
import { useCaseRecordEdit } from './useCaseRecordEdit';
import { DeleteButton, Form, FormGrid, OccupyFreeSpace, SaveButton, TabPanel, TabsHeader, useSaveable, useTabsState } from '../../toolympus/components/primitives';
import { Divider, IconButton, Typography } from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import { useItemWithControls } from '../../toolympus/api/useWithControls';
import { BiodevEditor } from '../Administration/BiodevEditor';
import { AttachedDocumentsBound } from '../Common/AttachedDocuments';
import { CaseRecordApiPath } from './useCaseRecordsList';
import { InboundLinksListOnCtx } from '../Common/Links/InboundLinksList';
import { InboundLinksContextProvider } from '../Administration/Editor/InboundLinksContext';
import { PluggableDocumentationButton } from '../../toolympus/components/Documentation';
import { DocumentLinksPane } from '../Common/DocumentLinks';

export const CaseRecordForm = () => {
  const { id } = useParams<{ id: string }>();
  const data = useCaseRecordEdit(+id);

  const { controls } = useItemWithControls(data, { schema: data.schema });

  useSaveable(data);

  const tabs = useTabsState([
    ["attributes","Attributes"],
    ["content","Content"],
    ["files","Files"],
  ], "attributes", "tab");

  return (
    <Form
      title="Case"
      headerItems={<>
        <OccupyFreeSpace />
        {data.hasChanges && <SaveButton action={() => data.save()} />}

        <DeleteButton
          remove={() => data.remove()}
          title="Delete the case?"
          button={<IconButton size="small"><DeleteOutlined /></IconButton>}
          />
        <PluggableDocumentationButton documentationKey="cases" />
      </>}
      >
      <FormGrid columns="2fr 1fr 1fr" style={{ alignItems: "flex-end" }}>
        {controls([
          ["title"],
          ["case_date"],
          ["is_published"],
        ])}
      </FormGrid>

      <TabsHeader state={tabs} />

      <TabPanel state={tabs.forTab("attributes")}>
        <FormGrid>
          {controls([
            ["countries", { autoComplete: true, multiSelectValueDisplay: "text" }],
            ["decision_body", { autoComplete: true }],
            ["source_url"],
            ["owner_id", { autoComplete: true }],
            ["created_by", { disabled: true }],
            ["created_at", { disabled: true, utcToLocal: true }],
          ])}
        </FormGrid>

        <FormGrid columns="1fr">
          {controls([
            ["topics", { autoComplete: true, multiSelectValueDisplay: "text" }],
          ])}


          <Typography variant="caption" component="p" color="textSecondary" style={{ marginTop: "1rem" }}>Description / abstract</Typography>
          <BiodevEditor
            key={`${data.data._id}`}
            content={data.data.description}
            update={v => data.update({ description: v })}
            />

          <Divider />

          <FormGrid columns="1fr 1fr" forceEvenColumns>

            <FormGrid columns="1fr" noMargin>
              <Typography variant="caption" color="textSecondary">
                Associated documents
              </Typography>

              <DocumentLinksPane
                thisRecordType="case_record"
                thisRecordId={data.data._id}
                />
            </FormGrid>

            <InboundLinksContextProvider this_record_type="case_record" this_record_id={`${data.data._id}`}>
              <InboundLinksListOnCtx label="Links" />
            </InboundLinksContextProvider>
          </FormGrid>


        </FormGrid>
      </TabPanel>

      <TabPanel state={tabs.forTab("content")}>
        <BiodevEditor
          key={`${data.data._id}`}
          content={data.data.content}
          update={v => data.update({ content: v })}
          />
      </TabPanel>

      <TabPanel state={tabs.forTab("files")}>
        <CaseRecordDocuments caseRecordId={data.data._id} />
      </TabPanel>
    </Form>
  );
}


const CaseRecordDocuments = (props: { caseRecordId: number }) => {
  return <AttachedDocumentsBound
    apiPath={CaseRecordApiPath}
    parentId={props.caseRecordId}
    documentLinkPrefix="/api/case-record/published/document"
    />
}