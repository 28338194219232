import React from 'react';
import { TopicSchema, useManageTopics } from './useManageTopics';
import { NewTopicDialog, TopicEditDialog } from './TopicEditDialog';
import { PluggableDocumentationButton } from '../../../../toolympus/components/Documentation';
import { TreeList } from '../../../../toolympus/components/Trees';
import { FieldDefinition } from '../../../../toolympus/components/schemed';



const Fields: FieldDefinition[] = [
  ["sort_order"],
] 

export const TopicList = () => {
  const data = useManageTopics();
  return (<>
    <TreeList
      data={data}
      newItemDialog={NewTopicDialog}
      editItemDialog={TopicEditDialog}
      extraFields={Fields}
      schema={TopicSchema}
      titleLabel="Название"
      headerItemsRight={<PluggableDocumentationButton documentationKey="dictionaries" />}
      />
  </>);
}
