import { useBrowserStoredValue } from "../../toolympus/hooks/useBrowserStoredValue";
import { useSingleSchema } from "../../toolympus/hooks/useSchema";
import { useSmartList } from "../../toolympus/hooks/useSmartList";

export interface CaseRecord {
  _id: number;
  title: string;
  case_date?: string;
  country?: string;
  decision_body?: string;
  topics?: string[] | null;
  source_url?: string;

  description?: any;
  content?: any;

  is_published?: boolean;
  
  owner_id?: string;
  created_at?: string;
  created_by?: string;
}

export const CaseRecordApiPath = "/api/case-record"

const CaseRecordTextFilter = (cr: CaseRecord) => `${cr.title} ${cr._id}`;

export const useCaseRecordSchema = () => {
  const { schema } = useSingleSchema(`${CaseRecordApiPath}/uiconfig`);
  return schema;
}

export const useCaseRecordsList = () => {
  const schema = useCaseRecordSchema();

  const assignedFilter = useBrowserStoredValue("me", undefined, "__iclrcbd_crlist_my_all");

  const data = useSmartList(CaseRecordApiPath, {
    sorting: {
      disallowedFields: ["description", "content"],
    },
    extraParams: {
      assigned: assignedFilter.value === "me" ? "me" : undefined,
    },
    viewDefault: "unpublished",
    lsKeysPrefix: "__iclrcbd_crlist",
    newRecordDefault: { title: "" },
    schema,
    textFilterFn: CaseRecordTextFilter,
  });

  return {
    ...data,
    assignedFilter,
  }
}

export type CaseRecordListData = ReturnType<typeof useCaseRecordsList>;
