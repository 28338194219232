
export interface TreeNode<T> {
  node?: T;
  children: T[];
}
export type ItemsTree<T> = Record<any, TreeNode<T>>;

export interface WithLevel {
  level: number;
}

export interface TreeItemsConfig<T> {
  idField: keyof T;
  parentIdField: keyof T;
  sortField: keyof T;
}


const NoParentCode = "::NO::";


export const assembleTree = <T,>(cfg: TreeItemsConfig<T>, records: T[]): ItemsTree<T> => {
  const { idField, parentIdField } = cfg;

  return records.reduce<ItemsTree<T>>(
    (r,item) => {
      const present = r[item[idField]] as unknown as TreeNode<T>;
      if(present) {
        present.node = item;
      } else {
        r[item[idField] as any] = { node: item, children: [] as T[] };
      }
      const parentCode = item[parentIdField] || NoParentCode;
      const presentParent = r[parentCode] as TreeNode<T>;
      if(presentParent) {
        presentParent.children = [...presentParent.children, item];
      } else {
        r[parentCode as any] = { children: [item] };
      }

      return r;
    },
    {}
  );
}

export function disassembleTree<T,>(cfg: TreeItemsConfig<T>, tree: ItemsTree<T>, parentId: any = NoParentCode, level: number = 0): (T & WithLevel )[] {
  const { idField, sortField } = cfg;
  const children = tree[parentId]?.children || [];
  return children
    .sort((a,b) => (a[sortField] || 0) <= (b[sortField] || 0) ? -1 : 1)
    .reduce<(T & WithLevel)[]>((r,item) => {
      r.push({ ...item, level });
      r.push(...disassembleTree(cfg, tree, item[idField], level+1))
      return r;
    }, []);
}

export const pathToRoot = <T,>(cfg: TreeItemsConfig<T>, tree: ItemsTree<T>, id: any): any[] => {
  if(!id) {
    return [];
  }
  const node = tree[id];
  if(!node.node) {
    return [];
  } else {
    return [node.node[cfg.idField], ...pathToRoot(cfg, tree, node.node[cfg.parentIdField])];
  }
}

export const treeWithDescendantsCodes = <T,>(cfg: TreeItemsConfig<T>, tree: ItemsTree<T>, id: any): any[] => {
  if(!id) {
    return [];
  }
  const node = tree[id] as TreeNode<T>;
  if(!node.node) {
    return [];
  } else {
    return [
      node.node[cfg.idField],
      ...((node.children || [])
        .map(child => treeWithDescendantsCodes(cfg, tree, child[cfg.idField]))
        .reduce((r,items) => { r.push(...items); return r;}, []))];
  }
}
