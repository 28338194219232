import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { RecordLink, useInboundLinks } from '../../Administration/Editor/InboundLinksContext';
import { Avatar, Chip, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { CallMade } from '@mui/icons-material';
import { ThemeSettings } from '../../../theme';
import { Tooltip } from '../../../toolympus/components/primitives';

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 0.5rem;
`;

const ChipX = styled(Chip)`
  background: ${ThemeSettings.colors.secondaryShade.s4};
  max-width: 500px;

  & .MuiAvatar-root {
    background: transparent;
  }
  & .link-btn {
    transform: scale(0.75);
  }
`;

interface Props {
  thisSubId: string | null;
}

export const RecordTypeLabel: Record<string, string> = {
  case_record: "Case",
  instrument: "Instrument",
}

export const RecordTypeLinkPrefix: Record<string, string> = {
  case_record: "/case",
  instrument: "/instrument",
}

export const ContentSubIdQueryParam = "csid";

const generateLinkInternalLink = (link: RecordLink) => {
  const base = `${RecordTypeLinkPrefix[link.that_record_type]}/${link.that_record_id}`;
  const params: Record<string,string> = {};
  if(link.that_record_sub_id) {
    params[ContentSubIdQueryParam] = link.that_record_sub_id;
  }
  if(link.that_record_type === "instrument") {
    params["tab"] = "content";
  }

  return Object.keys(params).length
    ? `${base}?${Object.entries(params).map(([k,v]) => `${k}=${v}`).join("&")}`
    : base;
}

interface GenericBlipProps {
  description: string;
  url?: string;
  icon?: ReactNode;
  onDelete?: () => void;
}

export const LinkBlipGeneric = (props: GenericBlipProps) => {
  const { description, url, icon, onDelete } = props;
  return (
    <Tooltip text={description}>
      <ChipX
        size="small"
        avatar={<Avatar>
          <Link
            to={url || ""}
            target="_blank"
            onClick={e => { e.stopPropagation(); }}>
            <IconButton className="link-btn" size="small">{icon || <CallMade />}</IconButton>
          </Link>
        </Avatar>}
        label={description}
        onDelete={onDelete}
        />
    </Tooltip>
  )
}

export const LinkBlip = (props: { link: RecordLink, noRemove?: boolean }) => {
  const l = props.link;
  const ctx = useInboundLinks();
  const description = `${RecordTypeLabel[l.that_record_type] || l.that_record_type}: ${l.description || "..."}`;
  return (
    <LinkBlipGeneric
      description={description}
      url={generateLinkInternalLink(l)}
      onDelete={!props.noRemove ? () => ctx.removeLink(l) : undefined}
      />
  )
}


export const LinksBlips = (props: Props) => {
  const links = useInboundLinks();
  const id = props.thisSubId;

  const relevantLinks = id
    ?  links.links.filter(l => l.this_record_sub_id === id)
    : [];

  if(!relevantLinks.length) {
    return null;
  }
  
  return (
    <Wrapper contentEditable={false}>
      {relevantLinks.map(l => (
        <LinkBlip
          key={`${l.that_record_type}_${l.that_record_id}`}
          link={l}
          />))}
    </Wrapper>
  );
}
