import React from 'react';
import { CaseRecord, useCaseRecordsList } from './useCaseRecordsList';
import { ButtonOptionPicker, SimpleList } from '../../toolympus/components/primitives';
import { useHistory } from 'react-router-dom';
import { useFields } from '../../toolympus/components/schemed';
import { Typography } from '@mui/material';
import { SimplePowerDocViewer } from '../../toolympus/components/PowerDoc/SimplePowerDocViewer';
import { CreateCaseRecordDialog } from './CreateCaseRecordDialog';
import { PluggableDocumentationButton } from '../../toolympus/components/Documentation';


export const CaseRecordsList = () => {
  const data = useCaseRecordsList();
  const history = useHistory();

  const fields = useFields({
    defaultFields: [
      "title",
      "case_date",
      "countries",
      "decision_body",
      "created_at",
    ],
    blacklist: ["content", "country", "search_highlights"],
    schema: data.schema,
    storageKey: "_biodev_case_records_fields",
    extraSettings: {
      description: { tableControl: SimplePowerDocViewer },
      createdAt: { utcToLocal: true },
    }
  });

  return (<>
    <SimpleList
      data={{
        ...data,
        filter: data.filter.filter,
        setFilter: data.filter.setFilter,
        create: () => { data.newRecord.startEditing(); return Promise.resolve({ _id: -1 } as CaseRecord); }
      }}
      fields={[
      ]}
      tabs={[
        ["Drafts", "unpublished"],
        ["Published", "public"],
        ["All", "all"],
      ]}
      schema={data.schema}
      getLinkValue={cr => `/case/${cr._id}`}
      linkFields={[ "_id", "title" ]}
      title="Cases"
      searchAuto
      searchAutoFocus
      fieldSetings={fields}
      sorting={data.sorting}
      tableProps={{
        tailAnchor: data.limitTrigger.pagingAnchor,
      }}
      headerActions={<>
        <Typography variant="caption" color="textSecondary" style={{ alignSelf: "flex-end" }}>Cases: {data.count.data.total_records}</Typography>
      </>}
      headerViewActions={<>
        <ButtonOptionPicker
          options={[
            ["my", "me"],
            ["all", "all"],
          ]}
          selected={data.assignedFilter.value}
          setSelected={data.assignedFilter.update}
          />
      </>}
      headerTailActions={<>
        <PluggableDocumentationButton documentationKey="cases" />
      </>}
      />

    <CreateCaseRecordDialog
      {...data.newRecord}
      save={() => data.newRecord.save().then(cr => { history.push(`/case/${cr._id}`); return cr; })}
      schema={data.schema}
      />
  </>);
}
