import React from 'react';
import { useInstrumentsList, Instrument } from './useInstrumentsList';
import { ButtonOptionPicker, SimpleList } from '../../toolympus/components/primitives';
import { useHistory } from 'react-router-dom';
import { useFields } from '../../toolympus/components/schemed';
import { Typography } from '@mui/material';
import { SimplePowerDocViewer } from '../../toolympus/components/PowerDoc/SimplePowerDocViewer';
import { CreateInstrumentDialog } from './CreateInstrumentDialog';
import { PluggableDocumentationButton } from '../../toolympus/components/Documentation';


export const InstrumentsList = () => {
  const data = useInstrumentsList();
  const history = useHistory();


  const fields = useFields({
    defaultFields: [
      "title",
      "date_adoption",
      "date_effective",
      "created_at",
    ],
    blacklist: ["content", "search_highlights"],
    schema: data.schema,
    storageKey: "_biodev_instruments_fields",
    extraSettings: {
      description: { tableControl: SimplePowerDocViewer },
      createdAt: { utcToLocal: true },
    }
  });

  return (<>
    <SimpleList
      data={{
        ...data,
        filter: data.filter.filter,
        setFilter: data.filter.setFilter,
        create: () => { data.newRecord.startEditing(); return Promise.resolve({ _id: -1 } as Instrument); }
      }}
      fields={[
      ]}
      tabs={[
        ["Drafts", "unpublished"],
        ["Published", "public"],
        ["All", "all"],
      ]}
      schema={data.schema}
      getLinkValue={cr => `/instrument/${cr._id}`}
      linkFields={[ "_id", "title" ]}
      title="Instruments"
      searchAuto
      searchAutoFocus
      fieldSetings={fields}
      sorting={data.sorting}
      tableProps={{
        tailAnchor: data.limitTrigger.pagingAnchor,
      }}
      headerActions={<>
        <Typography variant="caption" color="textSecondary" style={{ alignSelf: "flex-end" }}>Instruments: {data.count.data.total_records}</Typography>
      </>}
      headerViewActions={<>
        <ButtonOptionPicker
          options={[
            ["my", "me"],
            ["all", "all"],
          ]}
          selected={data.assignedFilter.value}
          setSelected={data.assignedFilter.update}
          />
      </>}
      headerTailActions={<>
        <PluggableDocumentationButton documentationKey="instruments" />
      </>}
      />

    <CreateInstrumentDialog
      {...data.newRecord}
      save={() => data.newRecord.save().then(cr => { history.push(`/instrument/${cr._id}`); return cr; })}
      schema={data.schema}
      />
  </>);
}
