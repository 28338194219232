import React, { memo, ReactNode, useCallback } from 'react';
import styled from '@emotion/styled';
import { Buttons, Dialog, Form, FormGrid, LoadingIndicator, MoreActionsMenuButton, OccupyFreeSpace, PseudoLink2, SearchField, useCopyText } from '../primitives';
import { Button, DialogActions, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { Add, ArrowRight, Close, TrendingFlat, TrendingUp } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { ManageTreeData, TreeItemsConfigWithManage } from './useManageTree';
import { EditItemProps, NewItemProps } from '../../api/useNewItem';
import { FieldDefinition, MaybeFieldDefinition } from '../schemed';
import { Schema } from '../../hooks/useSchema';
import { WithLevel } from './treeHelpers';
import { TableDisplay } from '../schemed/TableDisplay';
import { StrippedIconButton } from '../primitives/StrippedButtons';

const LevelMarker = ({ level }: { level?: number }) => {
  return <>
    {!!level && <svg
      x="0px" y="0px"
      viewBox="0 0 20 15"
      style={{ width: 20, height: 15, marginLeft: (level || 0) * 20 -20 + 15 }}>
      <path style={{ fill: "none", stroke: "#00000000", strokeWidth: 1.5 }} d="M1,0V7H30" />
    </svg>}
  </>
}

const TableHeader = styled(TableHead)`
  & th:first-child {
    width: 100%;
  }
  & th:nth-child(n+2) {
    white-space: nowrap;
  }
`;

const ChangeParentPanel = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  gap: 0.25rem;

  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 300;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px #00000040;
  padding: 0.5rem 2rem 0.5rem 1rem;

  & .title {
    color: ${props => props.theme.palette.primary.main};
    border-bottom: 1px dotted ${props => props.theme.palette.primary.main};
  }

  & .to-root {
    color: ${props => props.theme.palette.primary.main};
    border: none;
    outline: none;
    background: none;
    padding: 0;
    margin: 0;
    font: inherit;
    cursor: pointer;
  }

  & .close {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
  }
`;

const CollapseButton = styled(ArrowRight)<{ isCollapsed?: boolean, isCollapsible?: boolean }>`
  cursor: pointer;
  transform: rotate(${props => props.isCollapsed ? 0 : 90}deg);
  opacity: ${props => props.isCollapsed ? 0.35 : 0.15};
  visibility: ${props => props.isCollapsible ? "visible" : "hidden"};
`;
CollapseButton.defaultProps = { role: "button" };

const ItemRowWrapper = styled(TableRow)<{ deHighlight?: boolean }>`
  opacity: ${props => props.deHighlight ? 0.35 : 1};
`;

interface ItemRowProps<T> {
  row: T;
  handleRowClick: (row: T) => void;
  isCollapsible?: boolean;
  isCollapsed?: boolean;
  toggleCollapsed: (r: T) => void;
  startChangingParent: (row: T) => void;
  startAddItem: (row: Partial<T>) => void;
  startRemoveItem: (row: T) => any;
  config: TreeItemsConfigWithManage<T>;

  extraFields?: MaybeFieldDefinition[];
  schema: Schema;
  deHighlight?: boolean;
}

const ItemRow = <T,>(props: ItemRowProps<T>) => {
  const { row,
    isCollapsible,
    isCollapsed,
    toggleCollapsed,
    handleRowClick,
    config,
    extraFields,
    schema,
    deHighlight
  } = props;

  const copyText = useCopyText();

  return (
    <ItemRowWrapper key={row[config.idField] as any} deHighlight={deHighlight}>
      <TableCell>
        <Buttons>
          <LevelMarker level={(row as unknown as WithLevel).level} />
          <CollapseButton
            isCollapsible={isCollapsible}
            isCollapsed={isCollapsed}
            onClick={() => toggleCollapsed && toggleCollapsed(row)}
            />
          <PseudoLink2
            border
            onClick={() => handleRowClick(row)}
            >
              {config.itemTitle(row)}
          </PseudoLink2>
        </Buttons>
      </TableCell>

      {((extraFields || []).filter(x => !!x) as FieldDefinition[]).map(([f, extra]) => (
        <TableCell key={f}>
          <TableDisplay
            field={f}
            schema={schema[f]}
            row={row}
            config={extra}
            />
        </TableCell>
        ))}
      <TableCell>
        <Buttons gap="small">
          <StrippedIconButton onClick={() => props.startAddItem({ [config.parentIdField]: row[config.idField] } as Partial<T>)}><Add /></StrippedIconButton>
          <StrippedIconButton onClick={() => props.startChangingParent(row)}><TrendingUp /></StrippedIconButton>
          
          <MoreActionsMenuButton
            strippedButton
            actions={[
              ["remove", <FormattedMessage id="common.delete" />, () => props.startRemoveItem(row)],
              ["id", <FormattedMessage id="common.copy_id_with_value" values={{ value: row[config.idField]}} />, () => copyText(`${row[config.idField]}`)],
            ]}
            />
        </Buttons>
      </TableCell>
    </ItemRowWrapper>
  )
}

const ItemRowMemo = memo(ItemRow);

interface Props<T> {
  data: ManageTreeData<T>;
  headerItemsLeft?: ReactNode;
  headerItemsRight?: ReactNode;
  titleLabel?: ReactNode;

  newItemDialog?: React.ComponentType<NewItemProps<Partial<T>, T>>;
  editItemDialog?: React.ComponentType<EditItemProps<T>>;

  extraFields?: MaybeFieldDefinition[];
  schema: Schema;
  title?: ReactNode;
  rowComponent?: React.ComponentType<ItemRowProps<T>>;
}

export const TreeList = <T,>(props: Props<T>) => {
  const { data, extraFields, schema } = props;

  const RowComponent = props.rowComponent || ItemRowMemo;

  const handleRowClick = useCallback((row: T) => {
    if(!!data.changeParent.item) {
      data.changeParent.selectNewParent(row);
    } else {
      data.editItem.startEditing(row);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!data.changeParent.item]);

  return (<>
    <Form
      title={props.title}
      noTitle={!props.title}
      headerItems={<>
        {props.newItemDialog && <IconButton color='primary' size="small" onClick={() => data.newItem.startEditing()}><Add /></IconButton>}
        <Button size="small" onClick={() => data.collapser.collapseAll()}><FormattedMessage id="common.collapse_all" /></Button>
        <Button size="small" onClick={() => data.collapser.uncollapseAll()}><FormattedMessage id="common.uncollapse_all" /></Button>
        {props.headerItemsLeft}

        <OccupyFreeSpace />

        {data.isLoading && <LoadingIndicator sizeVariant="m" />}
        <SearchField
            filter={data.filter.filter}
            setFilter={data.filter.setFilter}
            noButton
            autoFocus
            />
        {props.headerItemsRight}
      </>}>

      <TableContainer>
        <Table size="small">
            <TableHeader>
              <TableRow>
                <TableCell>
                  <Buttons>
                    <CollapseButton
                      isCollapsible={false}
                      isCollapsed={false}
                      onClick={() => {}}
                      />
                      {props.titleLabel}
                  </Buttons>
                </TableCell>
                {((extraFields || []).filter(x => !!x) as FieldDefinition[]).map(([f, extra]) => (
                  <TableCell key={f}>
                    {extra?.label || (schema[f]?.label_id ? <FormattedMessage id={schema[f].label_id} /> : schema[f]?.label) || ""}
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHeader>
            <TableBody>
              {data.data.filter(row => !data.collapser.isHidden(row)).map((row) => (
                <RowComponent
                  key={row[data.config.idField] as any}
                  row={row}
                  handleRowClick={handleRowClick as any}
                  isCollapsible={data.collapser.isCollapsible(row)}
                  isCollapsed={data.collapser.isCollapsed(row)}
                  toggleCollapsed={data.collapser.toggleCollapsed as any}
                  startAddItem={data.newItem.startEditing}
                  startChangingParent={data.changeParent.startChanging as any}
                  startRemoveItem={data.removeItem.run as any}
                  config={data.config as any}
                  extraFields={extraFields}
                  schema={schema}
                  deHighlight={!!data.filter.filter && !data.filter.filterData([row]).length}
                  />
                ))}
            </TableBody>
        </Table>
      </TableContainer>
    </Form>

    {props.newItemDialog &&
      <props.newItemDialog
        {...data.newItem}
        />}
    {props.editItemDialog &&
      <props.editItemDialog
        {...data.editItem}
        />}

    {!!data.changeParent.item &&
      <ChangeParentPanel>
        <Buttons>
          <Typography><FormattedMessage id="trees.change_parent.change_parent_for" /></Typography>
          <Typography className="title">{data.config.itemTitle(data.changeParent.item)}</Typography>
        </Buttons>
        
        <Typography variant="caption" color="textSecondary">
          <FormattedMessage id="trees.change_parent.change_parent_hint" />
          <button className="to-root" onClick={() => data.changeParent.selectNewParent("root")}>
            <FormattedMessage id="trees.change_parent.to_root" />
          </button>
        </Typography>
        
        <IconButton size="small" className="close" onClick={() => data.changeParent.cancel()}><Close /></IconButton>
      </ChangeParentPanel>}

    <Dialog
      dialogTitle={<FormattedMessage id="trees.change_parent.confirmation_dialog_title" />}
      noFullscreen
      isOpen={!!data.changeParent.item && !!data.changeParent.newParent}
      close={() => data.changeParent.cancel()}
      >
      <FormGrid columns="1fr max-content 1fr">
        <TextField value={data.changeParent.item ? data.config.itemTitle(data.changeParent.item) : ""} label={<FormattedMessage id="trees.change_parent.moving" />} />
        <IconButton size="small" style={{ gridRow: "span 2", alignSelf: "center" }}><TrendingFlat /></IconButton>
        <TextField value={data.changeParent.newParent ? data.changeParent.newParent === "root" ? "-" : data.config.itemTitle(data.changeParent.newParent) : ""} label={<FormattedMessage id="trees.change_parent.to_parent" />} />

        <TextField value={data.changeParent.item ? data.changeParent.item[data.config.idField] : ""} />
        <TextField value={data.changeParent.newParent ? data.changeParent.newParent === "root" ? "-" : data.changeParent.newParent[data.config.idField] : ""} />
      </FormGrid>

      <DialogActions>
        <Button onClick={() => data.changeParent.cancel()}><FormattedMessage id="common.cancel" /></Button>
        <Button variant="contained" color="primary" onClick={() => data.changeParent.commitChange()}><FormattedMessage id="common.save" /></Button>
      </DialogActions>
    </Dialog>
  </>);
}
