import React from 'react';
import { useParams } from 'react-router-dom';
import { useInstrumentEdit } from './useInstrumentEdit';
import { DeleteButton, Form, FormGrid, OccupyFreeSpace, SaveButton, TabPanel, TabsHeader, useSaveable, useTabsState } from '../../toolympus/components/primitives';
import { Divider, IconButton, Typography } from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import { useItemWithControls } from '../../toolympus/api/useWithControls';
import { BiodevEditor } from '../Administration/BiodevEditor';
import { PlaceholderField } from '../../toolympus/components/schemed';
import { AttachedDocumentsBound } from '../Common/AttachedDocuments';
import { InstrumentApiPath } from './useInstrumentsList';
import { InboundLinksContextProvider } from '../Administration/Editor/InboundLinksContext';
import { useScrollToContentSubIdFromQuery } from '../Common/Links/useScrollToContentSubIdFromQuery';
import { PluggableDocumentationButton } from '../../toolympus/components/Documentation';
import { DocumentLinksPane } from '../Common/DocumentLinks';

export const InstrumentForm = () => {
  const { id } = useParams<{ id: string }>();
  const data = useInstrumentEdit(+id);

  const { controls } = useItemWithControls(data, { schema: data.schema });

  useSaveable(data);

  useScrollToContentSubIdFromQuery();

  const tabs = useTabsState([
    ["attributes","Attributes"],
    ["content","Content"],
    ["files","Files"],
  ], "attributes", "tab");

  return (
    <Form
      title="Instrument"
      headerItems={<>
        <OccupyFreeSpace />
        {data.hasChanges && <SaveButton action={() => data.save()} />}

        <DeleteButton
          remove={() => data.remove()}
          title="Delete the instrument?"
          button={<IconButton size="small"><DeleteOutlined /></IconButton>}
          />
        <PluggableDocumentationButton documentationKey="instruments" />
      </>}
      >
      <FormGrid columns="3fr 1fr" style={{ alignItems: "flex-end" }}>
        {controls([
          ["title"],
          ["is_published"],
        ])}
      </FormGrid>

      <TabsHeader state={tabs} />

      <TabPanel state={tabs.forTab("attributes")}>
        <FormGrid>
          {controls([
            ["date_adoption"],
            ["date_effective"],
            ["created_at", { disabled: true, utcToLocal: true }],
            ["source_url"],
            ["depositary_url"],
            ["created_by", { disabled: true }],
            ["depositary", { autoComplete: true }],
            [PlaceholderField],
            ["owner_id", { autoComplete: true }],
          ])}
        </FormGrid>

        <FormGrid columns="1fr 1fr" forceEvenColumns>
          {controls([
            ["countries", { autoComplete: true, multiSelectValueDisplay: "text" }],
            ["topics", { autoComplete: true, multiSelectValueDisplay: "text" }],
          ])}
        </FormGrid>

        <FormGrid columns="1fr">

          <Typography variant="caption" component="p" color="textSecondary" style={{ marginTop: "1rem" }}>Overview</Typography>
          <BiodevEditor
            key={`${data.data._id}`}
            content={data.data.description}
            update={v => data.update({ description: v })}
            />

          <Divider />

          <Typography variant="caption" color="textSecondary">
            Associated documents
          </Typography>

          <DocumentLinksPane
            thisRecordType="instrument"
            thisRecordId={data.data._id}
            />

        </FormGrid>
      </TabPanel>

      <TabPanel state={tabs.forTab("content")}>
        <InboundLinksContextProvider this_record_id={data.data._id >= 0 ? data.data._id.toString() : ""} this_record_type="instrument">
          <BiodevEditor
            key={`${data.data._id}`}
            content={data.data.content}
            update={v => data.update({ content: v })}
            />
        </InboundLinksContextProvider>
      </TabPanel>

      <TabPanel state={tabs.forTab("files")}>
        <InstumentDocuments instrumentId={data.data._id} />
      </TabPanel>
    </Form>
  );
}

const InstumentDocuments = (props: { instrumentId: number }) => {
  return <AttachedDocumentsBound
    apiPath={InstrumentApiPath}
    parentId={props.instrumentId}
    documentLinkPrefix="/api/instrument/published/document"
    />
}